import { checkIfUserHasRequiredModule } from './module_service';
import {
  parseFallbackNlLocalizationFromDocumentType,
  generateConfigForUnknownDocumentType,
} from './document_type_generator';

let documentTypesConfig = {};

export const setDocumentTypesConfig = (newDocumentTypesConfig) => {
  documentTypesConfig = newDocumentTypesConfig;
};

//
// All these functions are direct copies from the shared `document_types_service.js`
// MAKE SURE TO KEEP THESE FUNCTIONS IDENTICAL WHEN CHANGES ARE MADE!
// VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

// Get the documentType by the given documentTypeId
// if it is configured simply return it.
// else generate one based on the docType.
export const getDocTypeByDocTypeId = (docTypeId) => documentTypesConfig[docTypeId]
  || generateConfigForUnknownDocumentType(docTypeId);

// Get the localization of a documentType by the given docTypeId
// if it is explicitly configured simply return it.
// else estimate one based on the docType.
export const getLocalizationForDocTypeId = (docTypeId, options = {}) => {
  if (!documentTypesConfig[docTypeId]) {
    return parseFallbackNlLocalizationFromDocumentType(docTypeId);
  }
  const { singular, locale = 'nl' } = options;
  const localization = documentTypesConfig[docTypeId].localization[locale]
    || documentTypesConfig[docTypeId].localization.nl;
  return typeof localization === 'string' ? localization : localization[singular ? 'singular' : 'plural'];
};

// Check if a docType has all the required labels
export const docTypeHasRequiredLabels = (docTypeConfig, requiredLabels) => requiredLabels
  .every((requiredLabel) => {
    if (Array.isArray(requiredLabel)) { // oneOfLabels needs to match one of the labels
      return requiredLabel.some((label) => docTypeConfig.labels.includes(label));
    }
    return docTypeConfig.labels.includes(requiredLabel);
  });

// Find documentTypes have a set of required labels.
export const findDocumentTypesByLabels = (requiredLabels, includeDeprecated) => {
  if (!requiredLabels || requiredLabels.length === 0) return Object.keys(documentTypesConfig);

  const docTypes = includeDeprecated
    ? documentTypesConfig
    : Object.fromEntries(
      Object.entries(documentTypesConfig).filter(([, docTypeConfig]) => !docTypeConfig.deprecated),
    );
  return Object.entries(docTypes).reduce((foundDocTypes, [docType, docTypeConfig]) => {
    if (docTypeHasRequiredLabels(docTypeConfig, requiredLabels)) {
      foundDocTypes[docType] = docTypeConfig;
    }
    return foundDocTypes;
  }, {});
};

// Check if the given modules match the documentTypes requirements.
export const modulesMeetDocumentTypeRequirements = (documentTypeConfig, modules) => (
  !documentTypeConfig.requiredModules // No module requirements for docType
  || documentTypeConfig.requiredModules // OR module requirement(s) exist and are all met.
    .every((requiredModule) => checkIfUserHasRequiredModule(modules, requiredModule))
);

// Get all documentTypes that the provided modules give access to.
export const getPrunedDocumentTypesByModules = (modules, { includeDeprecated }) => Object
  .fromEntries(
    Object.entries(documentTypesConfig)
      .filter(([, docTypeConfig]) => includeDeprecated || !docTypeConfig.deprecated)
      .filter(([, docTypeConfig]) => modulesMeetDocumentTypeRequirements(docTypeConfig, modules)),
  );

/**
 * Helper function for retrieving all currently supported Europarl document (typeDocu) codes.
 * @returns {[string]} - Array of all currently supported typeDocu codes.
 */
export const getAllSupportedEuroparlCodes = () => Object.keys(documentTypesConfig)
  .filter((docType) => docType.startsWith('EUROPARL-')) // Filter on all EUROPARL- documentTypes
  .map((docType) => docType.slice(9)); // Slice off the first 9 characters ('EUROPARL-') to get only the original typeDocu codes

/**
 * Helper function for retrieving all Europarl document types per institution.
 * @returns {object} Object with an array of documentType(Id)s per institution.
 */
export const getAllEuroparlDocumentTypesPerInstitution = () => ({
  EC: Object.entries(documentTypesConfig) // TODO rename EUROCOM
    .filter(([, docType]) => (docType.labels
      && docType.labels.includes('govBranch:European Commission')
      && !docType.deprecated))
    .map(([docTypeId]) => docTypeId),
  EP: Object.entries(documentTypesConfig) // TODO rename EUROPARL
    .filter(([, docType]) => (docType.labels
      && docType.labels.includes('govBranch:European Parliament')
      && !docType.deprecated))
    .map(([docTypeId]) => docTypeId),
  EUROCOU: Object.entries(documentTypesConfig)
    .filter(([, docType]) => (docType.labels
      && docType.labels.includes('govBranch:European Council')
      && !docType.deprecated))
    .map(([docTypeId]) => docTypeId),
});

export const getAllMultiColumnTypes = () => Object.entries(documentTypesConfig)
  .filter(([, docType]) => docType.isMultiColumn)
  .map(([docTypeId]) => docTypeId);


// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
// All these functions are direct copies from the shared `document_types_service.js`
// MAKE SURE TO KEEP THESE FUNCTIONS IDENTICAL WHEN CHANGES ARE MADE!
//
